<template>
  <v-stepper alt-labels v-model="curr">
    <v-stepper-header>
      <template v-for="n in steps">
        <v-stepper-step
          :key="n.i"
          :complete="curr > n.i"
          :step="n.i">
          <span class="text-center small-font">{{ n.name }}</span>

        </v-stepper-step>
        <v-divider
          v-if="n.i !== steps.length"
          :key="`${n.i}-line`"
        ></v-divider>
      </template>
    </v-stepper-header>
    <v-stepper-items>
      <v-container>
        <v-stepper-content
          v-for="n in steps"
          :key="`${n.i}-item`"
          :step="n.i">
          <div v-if="n.i === 1">
            <v-container>
              <v-row justify="start">
                <v-btn icon @click="prevStep(n.i)">
                  <v-icon>arrow_back</v-icon>
                </v-btn>
              </v-row>
              <v-row>
                <v-col>
                  <v-card-title
                    class="justify-center navy-text font-weight-bold text-center"
                  >
                    <v-row justify="center" class="d-flex d-sm-none">
                      <v-col cols="12" class="pa-0 ma-0 subtitle">
                        ตรวจสอบคุณสมบัติ
                      </v-col>
                      <v-col class="pa-0 ma-0 subtitle">
                        Member ของคุณ
                      </v-col>
                    </v-row>
                    <v-row justify="center" class="d-none d-sm-flex subtitle">
                      ตรวจสอบคุณสมบัติ Member ของคุณ
                    </v-row>
                  </v-card-title>
                </v-col>
              </v-row>
              <v-row justify="center" class="py-2">
                <v-card class="quali-card text-center"
                  @click="cumaPopup = true"
                >
                  <v-row justify="center" class="py-4">
                    <v-img
                      src="../../assets/image/cumalogo.png"
                      max-height="48px"
                      max-width="150px"
                    ></v-img>
                  </v-row>
                  <span class="navy-text">
                    เคยเรียน Chula Mooc Achieve
                  </span>
                </v-card>
              </v-row>
              <v-row justify="center" class="py-2">
                <v-card class="quali-card text-center"
                  @click="checkAP()"
                >
                  <v-row justify="center" class="py-4">
                    <v-img
                      src="../../assets/image/achieveplus-logo.svg"
                      max-height="48px"
                      max-width="150px"
                    ></v-img>
                  </v-row>
                  <span class="navy-text">
                    เคยซื้อสินค้าจาก Achieve Plus
                  </span>
                </v-card>
              </v-row>
              <v-row justify="center" class="py-2">
                <v-card class="quali-card text-center"
                  @click="requestPopup = true"
                >
                  <v-row justify="center" class="py-4">
                    <v-img
                      max-height="48px"
                      max-width="150px"
                    >
                      <v-icon color="#003A70" large>public</v-icon>
                    </v-img>
                  </v-row>
                  <span class="navy-text">
                    ซื้อสินค้า Achieve Plus จากระบบภายนอก
                  </span>
                </v-card>
              </v-row>
              <v-row justify="center" class="py-2">
                <span class="navy-text">หากไม่ตรงคุณสมบัติใดเลย</span>
              </v-row>
              <v-row justify="center" class="py-2">
                <v-btn class="button" color="error" to="/store">
                  <span class="white-text">ซื้อสินค้า</span>
                </v-btn>
              </v-row>
              <v-row justify="center" class="py-2">
                <span class="navy-text">หรือ</span>
              </v-row>
              <v-row justify="center" class="py-2">
                <a href="mailto:contact@achieve.plus">
                  <span class="navy-text caption email" >
                      <u>ติดต่อเจ้าหน้าที่</u>
                    </span>
                  </a>
              </v-row>
            </v-container>
          </div>

          <div v-if="n.i === 2">
            <v-container>
              <v-row justify="start">
                <v-btn icon @click="prevStep(n.i)">
                  <v-icon>arrow_back</v-icon>
                </v-btn>
              </v-row>
              <v-row justify="center">
                <span class="navy-text font-weight-bold subtitle">กรอกข้อมูลส่วนตัว</span>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" md="6" lg="8">
                  <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  >
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="fnameth"
                          label="ชื่อ"
                          :rules="thRules"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="lnameth"
                          label="นามสกุล"
                          :rules="thRules"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="fnameen"
                          label="FIRSTNAME"
                          :rules="enRules"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="lnameen"
                          label="LASTNAME"
                          :rules="enRules"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-menu
                          v-model="menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="date"
                              label="วันเกิด"
                              prepend-icon="event"
                              readonly
                              v-on="on"
                              :rules="required"
                              required
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="date" @input="menu = false">
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col>
                        <v-select
                          v-model="selectSex"
                          :items="sex"
                          dense
                          :rules="required"
                          label="เพศ"
                          required
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-autocomplete
                          v-model="selectDegree"
                          :loading="loading"
                          :items="degree"
                          :search-input.sync="searchDegree"
                          cache-items
                          hide-no-data
                          hide-details
                          label="ระดับการศึกษา"
                          :rules="required"
                          required
                        ></v-autocomplete>
                      </v-col>
                      <v-col>
                        <v-autocomplete
                          v-model="selectOccupation"
                          :loading="loading"
                          :items="occupation"
                          :search-input.sync="searchOccupation"
                          cache-items
                          hide-no-data
                          hide-details
                          label="อาชีพ"
                          :rules="required"
                          required
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="selectSalary"
                          :items="salary"
                          label="เงินเดือน (บาท)"
                          :rules="required"
                          required
                        ></v-select>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="tel"
                          label="เบอร์โทรศัพท์"
                          :rules="required"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="contactEmail"
                          label="Contact Email"
                          :rules="emailRules"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-card-title class="font-weight-bold navy-text">
                        ที่อยู่ปัจจุบัน
                      </v-card-title>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="address"
                          label="ที่อยู่ (เลขที่ห้อง บ้านเลขที่ อาคาร หมู่บ้าน หมู่ที่ ซอย ถนน)"
                          :rules="required"
                          required
                          outlined
                          height="100px"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <label class="caption">ค้นหาอัตโนมัติ </label><v-icon>search</v-icon>
                        <thai-address-input
                          class="v-text-field border-bottom"
                          type="search"
                          v-model="searchAddress"
                          input-class="v-input"
                          @selected="onSelected"
                          placeholder="โปรดระบุตำบล / อำเภอ / จังหวัด / รหัสไปรษณีย์"
                        ></thai-address-input>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          label="ตำบล / แขวง"
                          v-model="subdistrict"
                          readonly
                          :rules="required"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col>
                        <v-text-field
                          label="อำเภอ / เขต"
                          v-model="district"
                          readonly
                          :rules="required"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          type="province"
                          label="จังหวัด"
                          v-model="province"
                          readonly
                          @selected="onSelected"
                          :rules="required"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col>
                        <v-text-field
                          label="รหัสไปรษณีย์"
                          v-model="postalCode"
                          readonly
                          :rules="required"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>

              <v-row justify="center">
                <v-checkbox
                    v-if="!submit"
                    v-model="checkbox"
                    :error-messages="checkboxErrors"
                    label="ข้าพเจ้ายอมรับ <router-link to='/termofservice' target='_blank'>
                        ข้อกำหนดในการให้บริการ</router-link>
                    และได้ตรวจสอบข้อมูลอย่างถูกต้องทุกประการแล้ว"
                    required
                  >
                  <template v-slot:label>
                    <div>
                      ข้าพเจ้ายอมรับ
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a
                            target="_blank"
                            href="/termofservice"
                            @click.stop
                            v-on="on"
                          >
                            ข้อกำหนดในการให้บริการ
                          </a>
                        </template>
                        Opens in new tab
                      </v-tooltip>
                      และได้ตรวจสอบข้อมูลอย่างถูกต้องทุกประการแล้ว
                    </div>
                  </template>
                  </v-checkbox>
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                <v-col class="text-right">
                  <v-btn
                    color="error"
                    @click="prevStep(n.i)"
                    outlined>
                    <span class="bt-m">กลับ</span>
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn
                    color="error"
                    :disabled="!checkbox || !valid"
                    @click="nextStep(n.i)">
                    <span class="bt-m">ยืนยัน</span>
                  </v-btn>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-container>
          </div>

        </v-stepper-content>
      </v-container>
    </v-stepper-items>
    <v-dialog
      v-model="requestPopup"
      max-width="500"
    >
      <v-card>
        <v-toolbar>
          <v-toolbar-title>
            <span class="navy-text text-xs-center">
              ข้อมูลการซื้อสินค้าจาก ช่องทางอื่นๆ
            </span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              class="mt-4"
              name="ช่องทางที่สั่งซื้อ"
              label="ช่องทางที่สั่งซื้อ"
              type="string"
              v-model="buyingChannel"
              :rules="required"
              required>
            </v-text-field>
            <v-text-field
              class="mt-4"
              name="ชื่อผู้ซื้อ"
              label="ชื่อผู้ซื้อ"
              type="string"
              v-model="buyerName"
              :rules="required"
              required>
            </v-text-field>
            <v-text-field
              class="mt-4"
              name="อีเมลที่ใช้"
              label="อีเมลที่ใช้"
              type="email"
              v-model="buyerEmail"
              :rules="emailRules"
              required>
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-spacer></v-spacer>
          <v-btn
            color="#f5333f"
            outlined
            @click="requestPopup = false"
          >
            กลับ
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="nextStep(1)"
          >
            <span class="white-text">ถัดไป</span>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="cumaPopup"
      max-width="500"
    >
      <v-card>
        <v-toolbar>
          <v-toolbar-title>
            <span class="navy-text text-xs-center">
              ล็อกอินบัญชี Chula MOOC Achieve
            </span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              class="mt-4"
              name="username"
              label="USERNAME"
              type="email"
              v-model="username"
              :rules="emailRules"
              required>
            </v-text-field>
            <v-text-field
              class="mt-4"
              name="password"
              label="PASSWORD"
              id="password" type="password"
              required v-model="password"
              :rules="passwordRules">
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-spacer></v-spacer>
          <v-btn
            color="#f5333f"
            outlined
            @click="cumaPopup = false"
          >
            CANCEL
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="#f5333f"
            @click="cumaLogin()"
          >
            <span class="white-text">LOG IN</span>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-stepper>
</template>

<script>
import memberServices from '../../library/member';
import requestAcService from '../../firebase/firestore/requestAC';
import fieldValue from '../../firebase/firestore/fieldValue';

const { errorMsg } = require('../../static/errorMessage');

export default {
  data() {
    return {
      curr: 1,
      steps: [
        {
          i: 1,
          name: 'ตรวจสอบคุณสมบัติ',
        },
        {
          i: 2,
          name: 'กรอกข้อมูลส่วนตัวของท่าน',
        },
      ],
      qualified: false,
      valid: true,
      fnameth: '',
      lnameth: '',
      fnameen: '',
      lnameen: '',
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      searchOccupation: '',
      searchDegree: '',
      selectOccupation: '',
      selectDegree: '',
      position: '',
      sex: ['ชาย', 'หญิง', 'ไม่ระบุ'],
      selectSex: '',
      agesofwork: ['0-1', '2-3', '4-5', '6-10', 'มากกว่า 10 ปี'],
      workAge: '',
      occupation: ['ข้าราชการ', 'พนักงานเอกชน', 'นักธุรกิจ', 'แพทย์/เภสัชกร', 'วิศวกร', 'นักกฎหมาย', 'นักบัญชี/การตลาด', 'ครู/อาจารย์', 'นักเรียน/นักศึกษา', 'อื่น ๆ'],
      degree: ['ปริญญาเอก', 'ปริญญาโท', 'ปริญญาตรี', 'อนุปริญญา', 'ปวช.', 'ปวส.', 'มัธยมศึกษาตอนปลาย', 'มัธยมศึกษาตอนต้น', 'ต่ำกว่ามัธยมศึกษา', 'ไม่ระบุ'],
      salary: ['ต่ำกว่า 15,000 บาท', '15,001 - 30,000 บาท', '30,001 - 45,000 บาท', '45,001 บาทขึ้นไป'],
      selectSalary: '',
      tel: '',
      lineId: '',
      contactEmail: '',
      loading: false,
      dialog: false,
      address: '',
      subdistrict: '',
      district: '',
      province: '',
      postalCode: '',
      searchAddress: '',
      required: [value => !!value || 'Required.'],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      thRules: [
        v => !!v || 'Required',
        v => /^[ก-๏]+$/.test(v) || 'Thai language must be valid',
      ],
      enRules: [
        v => !!v || 'Required',
        v => /^[a-zA-Z\s]+$/.test(v) || 'English language must be valid',
      ],
      cumaPopup: false,
      cumaValid: false,
      username: '',
      password: '',
      complete: false,
      cumaEmail: '',
      cumaToken: '',
      purchasedWorkshop: '',
      passwordRules: [
        v => !!v || 'Password is required',
        v => v.length >= 8
                    || 'Password must be greater than 8 characters',
      ],
      checkbox: false,
      checkboxErrors: '',
      submit: false,
      acStatus: 'pending',
      requestPopup: false,
      requestAC: false,
      buyingChannel: '',
      buyerName: '',
      buyerEmail: '',
    };
  },
  watch: {
    steps(val) {
      if (this.curr > val) {
        this.curr = val;
      }
    },
    curr(val) {
      if (val === 2) {
        this.setUserData();
      }
    },
    cumaPopup(val) {
      if (val && this.cumaEmail) {
        this.cumaPopup = false;
        this.$store.dispatch('setLoading', true);
        this.$store.dispatch('cumaCheckEnroll', { token: this.cumaToken })
          .then(() => {
            const { cuma } = this.$store.getters;
            if (cuma) {
              this.curr += 1;
              this.acStatus = 'successful';
            } else {
              this.$store.dispatch('setError', errorMsg['cuma/never']);
            }
            this.$store.dispatch('setLoading', false);
          }).catch(() => {
            this.$store.dispatch('setError', errorMsg['cuma/error']);
            this.$store.dispatch('setLoading', false);
          });
      } else {
        this.cumaPopup = val;
      }
    },
  },
  methods: {
    onSelected(address) {
      this.subdistrict = address.subdistrict;
      this.district = address.district;
      this.province = address.province;
      this.postalCode = address.postalCode;
    },
    recordUserData() {
      const data = {
        fth: this.fnameth,
        lth: this.lnameth,
        fen: this.fnameen,
        len: this.lnameen,
        dob: this.date,
        degree: this.selectDegree,
        sex: this.selectSex,
        occupation: this.selectOccupation,
        salary: this.selectSalary,
        tel: this.tel,
        contactEmail: this.contactEmail,
        address: this.address,
        subdistrict: this.subdistrict,
        district: this.district,
        province: this.province,
        postalCode: this.postalCode,
        PDPA: true,
        isMember: true,
        memberLevel: 'Basic',
        achieveChallenge: this.acStatus,
        memberStartDate: fieldValue.serverTimestamp(),
      };
      const { uid } = this.$store.getters.user;
      this.$store.dispatch('setProfile', { uid, data })
        .then(() => {
          this.$store.dispatch('getProfile', { uid });
        });
    },
    async checkPurchase() {
      const { profile } = this.$store.getters;
      if (profile && profile.workshopList && profile.workshopList.length) {
        this.purchasedWorkshop = true;
      } else {
        this.purchasedWorkshop = false;
      }
      if (profile.cumaEmail && !this.purchasedWorkshop) {
        this.showLink = false;
        this.cumaEmail = profile.cumaEmail;
        const token = profile.cumaToken;
        await this.$store.dispatch('cumaCheckEnroll', { token })
          .then(() => {
            const { cuma } = this.$store.getters;
            if (cuma) {
              this.cumaValid = true;
            } else {
              this.$store.dispatch('setError', errorMsg['cuma/never']);
            }
          })
          .catch(() => {
            this.cumaValid = false;
            this.$store.dispatch('setError', errorMsg['cuma/never']);
          });
      }
    },
    async cumaLogin() {
      this.$store.dispatch('setLoading', true);
      const dup = await memberServices.checkDuplicateEmail(this.username);
      if (!dup) {
        this.$store.dispatch('cumaLink', { username: this.username, password: this.password })
          .then(async () => {
            const { cumaLinked } = this.$store.getters;
            if (cumaLinked) {
              const { uid } = this.$store.getters.user;
              this.$store.dispatch('getProfile', { uid })
                .then(() => {
                  const { cumaToken } = this.$store.getters.profile;
                  this.$store.dispatch('cumaCheckEnroll', { token: cumaToken })
                    .then(() => {
                      const { cuma } = this.$store.getters;
                      if (cuma) {
                        this.curr += 1;
                        this.setUserData();
                        this.acStatus = 'successful';
                        this.cumaPopup = false;
                      } else {
                        this.$store.dispatch('setError', errorMsg['cuma/linked']);
                        this.$store.dispatch('setLoading', false);
                      }
                    });
                }).catch(() => {
                  this.$store.dispatch('setError', errorMsg['cuma/error']);
                  this.$store.dispatch('setLoading', false);
                });
            }
          })
          .catch(() => {
            this.$store.dispatch('setError', errorMsg['cuma/error']);
            this.$store.dispatch('setLoading', false);
          });
      } else {
        this.$store.dispatch('setError', errorMsg['cuma/linked']);
      }
      this.$store.dispatch('setLoading', false);
    },
    async sendRequest() {
      const { uid } = this.$store.getters.user;
      const data = {
        uid,
        channel: this.buyingChannel,
        name: this.buyerName,
        email: this.buyerEmail,
        status: 'pending',
        cerated: fieldValue.serverTimestamp(),
      };
      await requestAcService.sendRequest({ uid, data });
    },
    async nextStep(n) {
      if (n === this.steps.length) {
        console.log('done');
        this.$store.dispatch('setLoading', true);
        await this.recordUserData();
        if (this.requestAC) this.sendRequest();
        this.$router.push('/member/success');
        this.$store.dispatch('setLoading', false);
      } else {
        let nextable = false;
        switch (n) {
          case 1:
            nextable = this.$refs.form.validate();
            if (nextable) {
              this.requestAC = true;
              this.requestPopup = false;
            }
            break;
          default:
            nextable = false;
        }
        if (nextable) {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
          this.curr = n + 1;
          this.valid = false;
        }
      }
    },
    prevStep(n) {
      if (n === 1) {
        this.$router.push('/member');
      } else {
        this.curr = n - 1;
      }
    },
    async apply() {
      await memberServices.applyMember();
    },
    setUserData() {
      const { profile } = this.$store.getters;
      this.fnameth = profile && profile.fth ? profile.fth : null;
      this.lnameth = profile && profile.lth ? profile.lth : null;
      this.fnameen = profile && profile.fen ? profile.fen : null;
      this.lnameen = profile && profile.len ? profile.len : null;
      this.date = profile && profile.dob ? profile.dob : null;
      this.selectSex = profile && profile.sex ? profile.sex : null;
      this.selectOccupation = profile && profile.occupation ? profile.occupation : null;
      this.selectDegree = profile && profile.degree ? profile.degree : null;
      this.workAge = profile && profile.workAge ? profile.workAge : null;
      this.selectSalary = profile && profile.salary ? profile.salary : null;
      this.tel = profile && profile.tel ? profile.tel : null;
      this.contactEmail = profile && profile.contactEmail ? profile.contactEmail : null;
      this.address = profile && profile.address ? profile.address : null;
      this.subdistrict = profile && profile.subdistrict ? profile.subdistrict : null;
      this.district = profile && profile.district ? profile.district : null;
      this.province = profile && profile.province ? profile.province : null;
      this.postalCode = profile && profile.postalCode ? profile.postalCode : null;
    },
    checkAP() {
      this.$store.dispatch('setLoading', true);
      const { profile } = this.$store.getters;
      const { workshopList, pathwayList } = profile;
      if ((workshopList && workshopList.length) || (pathwayList && pathwayList.length)) {
        this.acStatus = 'successful';
        this.curr += 1;
      } else {
        this.$store.dispatch('setError', errorMsg['req/purchased']);
      }
      this.$store.dispatch('setLoading', false);
    },
  },
  async mounted() {
    this.$store.dispatch('setLoading', true);
    const { uid } = this.$store.getters.user;
    await this.$store.dispatch('getProfile', { uid });
    const { profile } = this.$store.getters;
    this.cumaEmail = profile && profile.cumaEmail ? profile.cumaEmail : null;
    this.cumaToken = profile && profile.cumaToken ? profile.cumaToken : null;
    this.$store.dispatch('setLoading', false);
  },
};
</script>

<style lang="sass" scoped>
  .quali-card
    width: 310px
  .red-box
    z-index: 10
    position: absolute
    width: 600px
    background-color: red
  .red-box-mobile
    width: 300px !important
  .hover-float
    cursor: pointer
    -webkit-transition: margin 0.3s ease-out
    -moz-transition: margin 0.3s ease-out
    -o-transition: margin 0.3s ease-out
  .hover-float:hover
    margin-top: -10px
    margin-bottom: 10px
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)
  .hover-link:hover
    color: red !important
  .border-bottom
    border-bottom-style: solid !important
    border-bottom-width: 1px !important
    border-bottom-color: rgba(0,0,0,0.38) !important
  .small-font
    font-size: 0.8rem
  .bt-m
    font-family: Prompt
</style>
