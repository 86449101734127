<template>
  <v-row>
    <v-col>
    <!-- Desktop -->
    <v-navigation-drawer class="hidden-md-and-down"
      floating
      permanent
    >
    <v-list>
      <v-list-item-group>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.link"
        >
          <v-list-item-avatar>
            <v-img
              :src="`${item.imgicon}`"
              contain
              height="32px">
            </v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{item.title}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <!-- <v-list>
      <v-list-item-title
        v-for="item in items"
        :key="item.title"
        :to="item.link"
      >
        <v-list-item-action>
          <v-icon>{{ item.icon }}</v-icon>
          <v-img
            :src="`${item.imgicon}`"
            contain
            height="32px">
          </v-img>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item-title>
    </v-list> -->
    </v-navigation-drawer>
    <!-- Tablet -->
    <v-tabs class="hidden-lg-and-up hidden-sm-and-down"
      centered
      show-arrows
      >
      <v-tab
        v-for="item in items"
        :key="item.title"
        :to="item.link"
        >
          <v-img
            class="mr-2"
            :src="`${item.imgicon}`"
            contain
            width="32px"
            height="32px">
          </v-img>
          {{ item.title }}

        <!-- <v-icon>{{ item.icon }}</v-icon> -->
      </v-tab>
    </v-tabs>
    <!-- Mobile -->
      <v-tabs
        class="hidden-md-and-up"
        centered
        >
        <v-tab
          v-for="item in items"
          :key="item.title"
          :to="item.link"
          >
          <!-- <v-icon>{{ item.icon }}</v-icon> -->
          <v-img
            :src="`${item.imgicon}`"
            contain
            height="30px"
            width="30px"
            >
          </v-img>
          <!-- <v-list-item-action>
            <v-img
              :src="`${item.imgicon}`"
              contain
              height="30px">
            </v-img>
           </v-list-item-action> -->
        </v-tab>
      </v-tabs>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          title: 'MY PROFILE', icon: 'account_box', imgicon: require('@/assets/image/icon-01.svg'), link: '/profile/index',
        },
        {
          title: 'MEMBER', icon: 'card_membership', imgicon: require('@/assets/image/icon-02.svg'), link: '/profile/member',
        },
        // {
        //   title: 'MY ACHIEVEMENT', icon: 'school',
        // imgicon: require('@/assets/image/icon-03.svg'), link: '/profile/courses',
        // },
        // {
        //   title: 'MY WORKSHOPS', icon: 'folder_special',
        // imgicon: require('@/assets/image/icon-04.svg'), link: '/profile/workshops',
        // },
        {
          title: 'PAYMENT', icon: 'payment', imgicon: require('@/assets/image/icon-05.svg'), link: '/profile/payment',
        },
        // {
        //   title: 'STUDIO', icon: 'studio',
        // imgicon: require('@/assets/image/icon-04.svg'), link: '/studio',
        // },
      ],
      right: null,
    };
  },
};
</script>

<style scoped>
.primary--text{
  color: #f5333f !important;
  caret-color: #f5333f !important;
}
a{
  color: #f5333f;
}
.v-navigation-drawer{
  z-index: 1;
}
.con-cen{
  justify-content: center;
}
</style>
