import firebase from '../index';

const request = firebase.firestore().collection('requestAC');

const sendRequest = ({ uid, data }) => request.doc(uid)
  .set(data, { merge: true });

export default {
  sendRequest,
};
