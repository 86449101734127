<template>
  <v-container>
    <v-container class="white-bg">
    <h3></h3>
    <v-row justify="center">
      <v-col cols="12" lg="3">
        <ProfileNavbar />
      </v-col>
      <v-col cols="12" lg="9">
        <router-view />
      </v-col>
    </v-row>
    <!-- <v-layout row wrap>
      <v-flex lg3 xl2>
        <ProfileNavbar />
      </v-flex>
      <v-flex xs12 lg9 xl10>
        <router-view />
      </v-flex>
    </v-layout> -->
    </v-container>
  </v-container>
</template>

<script>
import ProfileNavbar from '@/components/ProfileNavbar.vue';

export default {
  components: {
    ProfileNavbar,
  },
  created() {
    const { user } = this.$store.getters;
    const { uid } = user;
    this.$store.dispatch('getProfile', { uid });
  },
};
</script>

<style>

</style>
