<template>
  <body>
    <div class="banner-bg">
      <div class="banner-content">
        <v-container>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-img max-height="240" contain class="mgt-12px"
              :src="require('@/assets/image/workshop6-3.jpg')"
            />
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <div class="white-text heading mgt-12px">
                {{ title }} <br/>
                <div><span>({{ date }})</span></div>
                <br/>
                <!-- {{price}} -->
                Earlybird 3,900 บาท (1 - 18 ส.ค. 2562) <br>
                Achieve Member 2,900 บาท
                <div class="text-right">
                  จากราคาเต็ม {{ price }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <v-app class="ct-mw">
      <v-container>
        <v-row>
          <!-- Mobile -->
          <v-col cols="12">
            <v-row justify="center">
              <router-link
                v-if="isSale"
                :to="`/payment/cart/${sku}`"
                class="button hidden-md-and-up ltsp">
                <v-btn color="error"><strong> BUY NOW </strong></v-btn>
              </router-link>
              <v-btn
                v-else
                class="button hidden-md-and-up ltsp"
                disabled
              >
                <strong> SOLD OUT </strong>
              </v-btn>
            </v-row>

          </v-col>
          <v-col cols="12" sm="12" md="4" class="hidden-md-and-up">
            <v-container class="white-bg shadow mt-24px" >
              <br/>
              <v-row dense no-gutters
                v-for="salebox in salebox"
                :key="salebox.id">
                <v-col cols="2">
                  <v-row justify="center">
                    <v-img
                      :src="`${salebox.imgicon}`"
                      max-width="30px">
                    </v-img>
                  </v-row>
                </v-col>
                <v-col v-if="'link' in salebox" class="text-content">
                  <a :href="`${salebox.link}`">
                    {{ salebox.title }}</a>
                </v-col>
                <v-col v-else class="text-content">
                  {{ salebox.title }}
                </v-col>
                <!-- <v-col md="4" class="d-none d-sm-flex"></v-col>
                <v-col cols="2" sm="2" md="4" class="text-xs-center "> -->
                  <!-- <v-icon>{{ salebox.icon }}</v-icon> -->
                  <!-- <v-img
                    :src="`${salebox.imgicon}`"
                    width="30px">
                  </v-img>
                </v-col>
                <v-col cols="10" sm="10" md="4">
                  <div class="text-xs-left pdr-16px">{{ salebox.title }}</div>
                </v-col> -->
              </v-row>
              <br/>
              <v-row dense no-gutters>
                <v-col cols="2">
                  <br/>
                  <v-row justify="center">
                    <v-img
                      :src="require('@/assets/image/icon-14.svg')"
                      max-width="30px">
                    </v-img>
                  </v-row>
                    <!-- <v-icon>fas fa-chalkboard-teacher</v-icon> -->
                </v-col>
                <v-col >
                    <span v-if="preInstructor"><strong>{{preInstructor}}</strong><br/></span>
                    <span v-if="instructor">{{instructor}}<br/></span>
                    <span v-if="department">{{department}}<br/></span>
                    <span v-if="faculty">{{faculty}}<br/></span>
                    <span v-if="university">{{university}}<br/></span><br/>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <!-- About Product  -->
          <v-col cols="12" md="8">
            <v-content class="white-bg mt-24px ">
              <div class="pd-24px text-content">
                เคยไหมครับที่เกิดคำถามว่า อะไรคือสิ่งที่ฉันทำได้ดี ?
                ทำไมหลาย ๆ ครั้งโปรเจ็กต์ที่คิดว่าเจ๋งสุด ๆ
                แต่พอนำเสนอหัวหน้ากลับโดนปัดตกทุกที ?
                ทำไมเพื่อนร่วมงานถึงไม่เห็นด้วยกับไอเดียฉัน ?

                <br><br>

                ปัญหาเหล่านี้จะหมดไป เพียงแค่คุณ
                เข้าใจตัวเอง และ เข้าใจผู้อื่น
                ผ่านเครื่องมือสำคัญอย่าง ‘เอ็นเนียแกรม’
                ที่จะช่วยบอกว่าคุณเป็นคนลักษณะแบบไหน
                เรามีปฎิกิริยาที่โต้ตอบอัตโนมัติกับเหตุการณ์ต่าง ๆ อย่างไร
                เพื่อพัฒนาตัวตน ปลดล็อคศักยภาพและก้าวข้ามขีดความสามารถในการทำงานของคุณ

                <br><br>

                และนี่เป็น 4 ประโยชน์สำคัญของเอ็นเนียแกรม
                ที่จะช่วยให้ธุรกิจของคุณเติบโตอย่างก้าวกระโดด

                <br><br>

                <strong>1. รู้จักตัวเองมากยิ่งขึ้น </strong><br>
                เพื่อน ๆ อาจจะเคยได้ยินคำว่า “การเข้าใจตนเอง” หรือ “การรู้จักตนเอง”
                มาบ้างแล้ว แต่อาจเกิดคำถามต่อมาว่า
                แล้วเราจะรู้ได้อย่างไรว่าเราเป็นคนแบบไหน
                เอ็นเนียแกรม เป็นเครื่องมือสำคัญที่ทำให้เรารู้จักตัวเองดียิ่งขึ้น
                เมื่อเราและเพื่อนร่วมงานในองค์กรเข้าใจความต้องการของตัวเอง
                จะช่วยให้ประสิทธิภาพโดยรวมขององค์กรดียิ่งขึ้นถ้าคุณไม่รู้จักตัวเอง
                ก็มีโอกาสสูงมากที่คุณจะตัดสินใจผิดพลาดที่จะส่งผลในทางลบทั้งกับตัวเองและผู้อื่น

                <br><br>

                <strong>2. พัฒนาการสื่อสารกับเพื่อนร่วมงานให้ดีขึ้น</strong><br>
                ยิ่งเรารู้จักตนเองและเพื่อนร่วมงานคุณดีเท่าไหร่
                ก็จะยิ่งสื่อสารได้อย่างมีประสิทธิภาพมากเท่านั้น
                เอ็นเนียแกรมจะช่วยให้คุณเข้าใจมุมมองความคิดของผู้อื่นมากขึ้น
                รู้ว่าเขาจะรู้สึกอย่างไรกับสถานการณ์ต่างๆ ที่เกิดขึ้น
                เพื่อให้คุณสามารถออกแบบวิธีการสื่อสารกับคนแต่ละประเภทได้ดีขึ้น
                นอกจากนี้ยังลดความเสี่ยงในการเกิดข้อขัดแย้งจากการสื่อสารที่ผิดพลาดด้วย

                <br><br>

                <strong>3. คลี่คลายปัญหาต่าง ๆ ในองค์กร</strong><br>
                หลาย ๆ ครั้ง ที่เราและเพื่อนร่วมงานมีความเห็นที่ไม่ลงรอยกัน
                ส่วนใหญ่แล้วปัญหาต่าง ๆ มักเกิดจากการสื่อสารที่ไม่ดี (poor communication)
                เมื่อเกิดปัญหาขึ้น
                เป็นเรื่องยากที่จะแก้ปัญหาเนื่องจากตัวเราเองที่มีเครื่องมือจัดการปัญหาที่จำกัด
                จินตนาการว่าเรามีเครื่องมือเพียงแค่ 1 จาก 9 เครื่องมือที่จะแก้ปัญหาได้

                <br><br>

                ในทางกลับกันถ้าเรารู้จักเครื่องมือทั้ง 9 แบบเป็นอย่างดี
                เราเข้าใจมากขึ้นว่าทำไมเพื่อนร่วมงานคนนี้ถึงคิดแบบนี้
                เราก็จะมีทักษะในการรับมือปัญหาต่าง ๆ ได้ดียิ่งขึ้น
                รับมือกับความคิดที่หลากหลายและหาทางแก้แบบ win - win ที่ช่วยแก้ปัญหาให้กับทุกคน

                <br><br>

                <strong>4. ดึงศักยภาพของทีมงานให้ Achieve ยิ่งขึ้น</strong><br>
                ในหนึ่งองค์กร มักจะต้องมีหน้าที่หรือทีมที่หลากหลาย
                เช่น ทีมการตลาด ทีมขาย ทีมวิศวกร ทีมครีเอทีฟ
                ซึ่งแต่ละตำแหน่งต้องการคุณสมบัติที่แตกต่างกัน
                องค์กรที่สามารถ Put the right man on the right job
                หรือ สามารถนำคนที่มีจุดแข็ง ไปทำงานในตำแหน่งที่ตรงกับทักษะและความชอบ
                จะส่งผลให้คนทำงานมีความสุข ได้แสดงศักยภาพ ทำให้องค์กรเติบโตอย่างรวดเร็ว

                <br><br>

                <strong>บรรยายโดย</strong><br>
                อาจารย์ ดร.ปอรรัชม์ ยอดเณร
                อาจารย์ประจำภาควิชาวาทวิทยาและสื่อสารการแสดง คณะนิเทศศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย

                <br><br>

                วิทยากรผู้เชี่ยวชาญด้านการเข้าใจตนเอง การสื่อสารภายในบุคคล การสื่อสารเชิงจิตตปัญญา
                และการสื่อสารอย่างเข้าอกเข้าใจ (Contemplative Communication)

                <br><br>

                <strong>กิจกรรมที่จะเกิดขึ้น</strong><br>
                - การบรรยายถึงลักษณะ ของคนทั้ง 9 รูปแบบ รวมถึงการเข้าใจคนในแต่ละรูปแบบ<br>
                - กิจกรรมเตรียมความพร้อมทั้งร่างกายและจิตใจเพื่อเข้าสู่การค้นหาลักษณ์ของตนเอง<br>
                - กิจกรรมการ ค้นหาลักษณ์ของตนจากแบบทดสอบที่ได้รับความน่าเชื่อถือ
                และจากการทำกิจกรรม ทั้งกลุ่มและเดี่ยว
                ภายใต้โจทย์หรือสถานการณ์ที่วิทยากรกำหนดให้
                เพื่อเปรียบเทียบลักษณ์ที่ได้จากการสะท้อนผ่านแบบทดสอบและผ่านกิจกรรม<br>
                - กิจกรรม การแลกเปลี่ยนเรียนรู้กันระหว่างผู้เรียน ผ่านการสนทนา
                เพื่อให้มองเห็นถึงความหลากหลายอันเกิดจากลักษณ์ที่แตกต่างกันของแต่ละบุคคล
                โดยวิทยากรจะเป็นผู้สร้างบรรยากาศให้เอื้อต่อการแบ่งปันเรื่องราวของตน
                และแลกเปลี่ยนเรื่องราวกับผู้เรียนคนอื่น อย่างเข้าอกเข้าใจซึ่งกันและกัน

                <br><br>

                <strong>ประกาศนียบัตร (Certificate)</strong><br>
                ผู้เข้าร่วมเวิร์กชอปทุกท่านจะได้รับประกาศนียบัตรเชิงปฎิบัติการจาก Achieve

                <br><br>

                <strong>วัน เวลา</strong><br/>
                - วันที่: 31 สิงหาคม 2562<br/>
                - เวลา: 08:30 - 17:00 น.<br/><br/>

                <strong>สถานที่</strong><br/>
                หอประชุมนิเทศศาสตร์ ชั้น 11 อาคารมงกุฎสมมติวงศ์
                คณะนิเทศศาสตร์​ จุฬาลงกรณ์มหาวิทยาลัย<br/><br/>

                พิเศษสุดกับราคาสุด Exclusive !!! เพียง 3,900 บาท ( จากราคาเต็ม 5,900 บาท )<br/>
                สำหรับผู้เรียน Achieve Member จะได้รับส่วนลดเหลือเพียง 2,900 บาท<br/><br/>


                สอบถามข้อมูลเพิ่มเติม<br/>
                LINE Official Account: <a href="https://line.me/R/ti/p/@achieveplus" target="_blank">@achieveplus</a><br/>
                Facebook: <a href="https://www.facebook.com/Achieve.plus.th" target="_blank">CHULA MOOC Achieve</a><br/><br/>
              </div>
              <!-- <v-row>
                <v-col cols="12" sm="6">
                  <v-img class="mg"
                    :src="require('@/assets/image/workshop-image-9.jpg')"
                  ></v-img>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-img class="mg"
                    :src="require('@/assets/image/workshop-image-10.jpg')">
                  <div class="fill-height bottom-gradient"></div>
                  </v-img>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-img class="mg"
                    :src="require('@/assets/image/workshop-image-11.jpg')">
                  <div class="fill-height bottom-gradient"></div>
                  </v-img>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-img class="mg"
                    :src="require('@/assets/image/workshop-image-12.jpg')">
                  <div class="fill-height bottom-gradient"></div>
                  </v-img>
                </v-col>


              </v-row> -->
            </v-content>
          </v-col>
          <!-- Desktop -->
          <v-col cols="12" md="4" class="hidden-sm-and-down">
            <v-content class="white-bg shadow mt-24px">
              <v-col cols="12">
                <v-row justify="center">
                  <router-link
                    v-if="isSale"
                    :to="`/payment/cart/${sku}`"
                    class="button ltsp">
                    <v-btn color="error"><strong> BUY NOW </strong></v-btn>
                  </router-link>
                  <v-btn
                    v-else
                    class="button ltsp"
                    disabled
                  >
                    <strong> SOLD OUT </strong>
                  </v-btn>
                </v-row>
              </v-col>
              <v-container>
                <br/>
                <v-row dense no-gutters
                  v-for="salebox in salebox"
                  :key="salebox.id">
                  <v-col cols="2">
                    <v-row justify="center">
                      <v-img
                        :src="`${salebox.imgicon}`"
                        max-width="30px">
                      </v-img>
                    </v-row>
                  </v-col>
                  <v-col v-if="'link' in salebox" class="text-content">
                    <a :href="`${salebox.link}`">
                      {{ salebox.title }}</a>
                  </v-col>
                  <v-col v-else class="text-content">
                    {{ salebox.title }}
                  </v-col>
                  <!-- <v-col md="4" class="d-none d-sm-flex"></v-col>
                  <v-col cols="2" sm="2" md="4" class="text-xs-center "> -->
                    <!-- <v-icon>{{ salebox.icon }}</v-icon> -->
                    <!-- <v-img
                      :src="`${salebox.imgicon}`"
                      width="30px">
                    </v-img>
                  </v-col>
                  <v-col cols="10" sm="10" md="4">
                    <div class="text-xs-left pdr-16px">{{ salebox.title }}</div>
                  </v-col> -->
                </v-row>
                <br/>
                <v-row dense no-gutters >
                  <v-col cols="2">
                    <br/>
                    <v-row justify="center">
                      <v-img
                        :src="require('@/assets/image/icon-14.svg')"
                        max-width="30px">
                      </v-img>
                    </v-row>
                      <!-- <v-icon>fas fa-chalkboard-teacher</v-icon> -->
                  </v-col>
                  <v-col class="text-content">
                    <span v-if="preInstructor"><strong>{{preInstructor}}</strong><br/></span>
                    <span v-if="instructor">{{instructor}}<br/></span>
                    <span v-if="department">{{department}}<br/></span>
                    <span v-if="faculty">{{faculty}}<br/></span>
                    <span v-if="university">{{university}}<br/></span><br/>
                  </v-col>
                </v-row>
              </v-container>

            </v-content>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </body>
</template>

<script>
export default {
  name: 'workshopDetail',
  props: ['workshopId'],
  data() {
    return {
      card_text: '',
      sku: 'ws0060006',
      workshop: [
        { title: 'See "Me" through Enneagram: รู้จักตัวตนเพื่อการสื่อสารที่สัมฤทธิ์ผลผ่านเอ็นเนียแกรม', date: '31/08/2562' },
      ],
      title: 'See "Me" through Enneagram: รู้จักตัวตนเพื่อการสื่อสารที่สัมฤทธิ์ผลผ่านเอ็นเนียแกรม',
      date: '31/08/2562',
      price: '5,900 บาท',
      salebox: [
        {
          title: 'อบรมเชิงปฏิบัติการ (workshop)', icon: 'fas fa-laptop-code', imgicon: require('@/assets/image/icon-06.svg'), id: 1,
        },
        {
          title: 'วันเสาร์ที่ 31 ส.ค. 2562', icon: 'fas fa-calendar-alt', imgicon: require('@/assets/image/icon-09.svg'), id: 2,
        },
        {
          title: '08:00 - 17:30 น.', icon: 'far fa-clock', imgicon: require('@/assets/image/icon-10.svg'), id: 3,
        },
        {
          title: 'หอประชุมนิเทศศาสตร์ ชั้น 11 อาคารมงกุฎสมมติวงศ์ คณะนิเทศศาสตร์​ จุฬาลงกรณ์มหาวิทยาลัย', icon: 'fas fa-map-marker-alt', imgicon: require('@/assets/image/icon-11.svg'), id: 4,
        },
        {
          title: 'ระดับพื้นฐาน (Fundamental level)', icon: 'fas fa-tasks', imgicon: require('@/assets/image/icon-12.svg'), id: 5,
        },
        {
          title: 'Achieve+ Member รับส่วนลด 25%', link: '/member', icon: 'fas fa-star', imgicon: require('@/assets/image/icon-13.svg'), id: 6,
        },
      ],
      isSale: new Date('2019-08-30T09:00:00.000Z') > new Date(),
      preInstructor: 'บรรยายโดย',
      instructor: 'อาจารย์ ดร.ปอรรัชม์ ยอดเณร',
      department: 'ภาควิชาวาทวิทยาและสื่อสารการแสดง',
      faculty: 'คณะนิเทศศาสตร์',
      university: 'จุฬาลงกรณ์มหาวิทยาลัย',
    };
  },
};
</script>
<style scoped>
.ct-mw{
  max-width: 1100px;
  margin: auto;
}
.banner-bg{
  background-color: #001544;
  /* margin-left: -600px;
  margin-right: -600px; */
}
.banner-content{
  max-width: 1100px;
  margin: auto;
}
.content{
  height: 1500px;
  margin: auto;
  max-width: 1200px;
}
.heading{
  font-size: 1.3125rem;
  font-weight: 600;
  letter-spacing: 2px;
}
.pd-24px{
  margin-left: 31px;
  margin-right: 32px;
}
.pd-12px{
  padding-left: 12px;
}
.pdr-16px{
  padding-left: 24px;
  padding-right: 16px;
}
.white-bg{
  box-shadow: #001544;
  border: #001544;
}
.shadow{
  box-shadow: 2px 2px 10px #dfdfdf;
  border-color: #001544;
}
.mt-24px{
  margin-top: 24px;
}
.mlr-24px{
  margin-left: 24px;
  margin-right: 24px;
}
.v-icon{
  font-size: 16px;
}
.mgt-12px{
  margin-top: 12px;
}
.v-btn__content{
  font-weight: 700;
}
.ltsp{
  font-weight: 700;
  letter-spacing: 2px;
}
.mg{
  margin-bottom: 16px;
}
.text-content{
  font-size: 0.875rem;
}
@media screen and (max-width: 770px){
  .heading{
    font-size: 1.0rem;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 1.4rem;
  }
}
</style>
