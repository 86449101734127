<template>
  <body>
    <div class="banner-bg">
      <div class="banner-content">
        <v-container>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-img max-height="240" contain class="mgt-12px"
              :src="require('@/assets/image/workshop1.jpg')"
            />
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <div class="white-text heading mgt-12px">
                {{ title }} <br/>
                <div><span>({{ date }})</span></div>
                <br/>
                {{price}}
                <!-- Earlybird 3,900 บาท (1 - 18 ส.ค. 2562) <br>
                Achieve Member 2,900 บาท
                <div class="text-right">
                  จากราคาเต็ม {{ price }}
                </div> -->
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <v-app class="ct-mw">
      <v-container>
        <v-row>
          <!-- Mobile -->
          <v-col cols="12">
            <v-row justify="center">
              <router-link
                v-if="isSale"
                :to="`/payment/cart/${sku}`"
                class="button hidden-md-and-up ltsp">
                <v-btn color="error"><strong> BUY NOW </strong></v-btn>
              </router-link>
              <v-btn
                v-else
                class="button hidden-md-and-up ltsp"
                disabled
              >
                <strong> SOLD OUT </strong>
              </v-btn>
            </v-row>

          </v-col>
          <v-col cols="12" sm="12" md="4" class="hidden-md-and-up">
            <v-container class="white-bg shadow " >
              <br/>
              <v-row dense no-gutters
                v-for="salebox in salebox"
                :key="salebox.id">
                <v-col cols="2">
                  <v-row justify="center">
                    <v-img
                      :src="`${salebox.imgicon}`"
                      max-width="30px">
                    </v-img>
                  </v-row>
                </v-col>
                <v-col v-if="'link' in salebox" class="text-content">
                  <a :href="`${salebox.link}`">
                    {{ salebox.title }}</a>
                </v-col>
                <v-col v-else class="text-content">
                  {{ salebox.title }}
                </v-col>
                <!-- <v-col md="4" class="d-none d-sm-flex"></v-col>
                <v-col cols="2" sm="2" md="4" class="text-xs-center "> -->
                  <!-- <v-icon>{{ salebox.icon }}</v-icon> -->
                  <!-- <v-img
                    :src="`${salebox.imgicon}`"
                    width="30px">
                  </v-img>
                </v-col>
                <v-col cols="10" sm="10" md="4">
                  <div class="text-xs-left pdr-16px">{{ salebox.title }}</div>
                </v-col> -->
              </v-row>
              <br/>
              <v-row dense no-gutters>
                <v-col cols="2">
                  <br/>
                  <v-row justify="center">
                    <v-img
                      :src="require('@/assets/image/icon-14.svg')"
                      max-width="30px">
                    </v-img>
                  </v-row>
                    <!-- <v-icon>fas fa-chalkboard-teacher</v-icon> -->
                </v-col>
                <v-col >
                    <span v-if="preInstructor"><strong>{{preInstructor}}</strong><br/></span>
                    <span v-if="instructor">{{instructor}}<br/></span>
                    <span v-if="department">{{department}}<br/></span>
                    <span v-if="faculty">{{faculty}}<br/></span>
                    <span v-if="university">{{university}}<br/></span><br/>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <!-- About Product  -->
          <v-col cols="12" md="8">
            <v-content class="white-bg mt-24px ">
              <div class="pd-24px text-content">
                กล้าเรียนรู้ ก้าวแรกสู่การนำ Python มาประยุกต์ใช้งานจริง <br/><br/>

                จากคอร์ส Data Science Pathway เราได้เรียนรู้ส่วนหนึ่งของ Python ไปบ้างแล้ว
                และมีกระแสตอบรับจากผู้เรียนว่าอยากเรียนรู้เนื้อหาและวิธีการที่เชิงลึกกว่าเดิม
                <br/><br/>

                เพราะ Achieve แคร์คุณมากกว่าหน้าจอ เราจึงได้จัดทำ Python Workshop for
                Clustering Customer Segmentation เพื่อพาผู้เรียนไปสู่อีกขั้นของการนำ Python
                ไปประยุกต์ใช้ในการทำงานจริง ผ่านการสอนจากผู้เชี่ยวชาญในศาสตร์แห่ง Data Science
                สอนโดยผศ.ดร.สุกรี สินธุภิญโญ ภาควิชาวิศวกรรมคอมพิวเตอร์ จุฬาลงกรณ์มหาวิทยาลัย
                <br/><br/>

                ผู้เรียนจะได้เรียนรู้วิธีนำข้อมูลมหาศาลที่มีอยู่ มาใช้ให้เกิดประโยชน์
                โดยการนำมาวิเคราะห์หา Insight ของลูกค้า เพื่อแบ่งลูกค้าออกเป็นกลุ่ม ๆ
                ตามความต้องการที่แตกต่างกัน เพื่อที่เราจะได้คิดกลยุทธ์ที่เหมาะสมกับลูกค้าแต่ละกลุ่ม
                และเพิ่มยอดขาย รวมถึงต่อยอดเพื่อพัฒนาผลิตภัณฑ์ต่าง ๆ <br/><br/>

                <strong>สิ่งที่ท่านจะได้รับจากการเข้าร่วม Workshop นี้</strong> ได้แก่<br/>
                - เข้าใจพฤติกรรม และความต้องการของลูกค้ามากยิ่งขึ้น โดยได้ทำจากข้อมูลจริงๆ
                ฝึกปฎิบัติ เข้าใจกระบวนการต่างๆ เพื่อนำไปใช้ประยุกต์ใช้ในธุรกิจจริง <br/>
                - เรียนรู้วิธีการวิเคราะห์ตัวแปรสำคัญที่ใช้ในการแบ่งกลุ่มลูกค้า <br/>
                - Networking กับอาจารย์และ Teaching Assistant ที่มีความรู้เชิงลึกด้าน
                Data Science ช่วยตอบทุกคำถามที่ท่านสงสัย <br/>
                - ได้รับใบประกาศนียบัตรสำหรับผู้เข้าร่วม Workshop จาก CHULA MOOC
                Achieve <br/><br/>

                พิเศษสำหรับ Achieve Member จ่ายเพียง 2,990 บาท จากราคาเต็ม 5,900 บาท<br/><br/>

                <strong>หมายเหตุ</strong><br/>
                **รับจำนวนจำกัดเพียง 30 ท่านเท่านั้น<br/>
                **ทุกท่านนำคอมพิวเตอร์ส่วนตัวมาเอง<br/><br/>


                สอบถามข้อมูลเพิ่มเติม<br/>
                LINE Official Account: <a href="https://line.me/R/ti/p/@achieveplus" target="_blank">@achieveplus</a><br/>
                Facebook: <a href="https://www.facebook.com/Achieve.plus.th" target="_blank">CHULA MOOC Achieve</a><br/><br/>
              </div>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-img class="mg"
                    src="https://scontent.fbkk2-7.fna.fbcdn.net/v/t1.0-9/54519676_381629692657392_1751208198376783872_o.jpg?_nc_cat=109&_nc_ht=scontent.fbkk2-7.fna&oh=3a0b6dbba2b50fc431ab885d1419fdf5&oe=5D60AA48"
                  ></v-img>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-img class="mg"
                    src="https://scontent.fbkk2-8.fna.fbcdn.net/v/t1.0-9/55738268_381629762657385_3030068473498697728_o.jpg?_nc_cat=103&_nc_ht=scontent.fbkk2-8.fna&oh=20275489211ebb20123c7668bbd8b4d7&oe=5D515CBB">
                  <div class="fill-height bottom-gradient"></div>
                  </v-img>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-img class="mg"
                    src="https://scontent.fbkk2-8.fna.fbcdn.net/v/t1.0-9/55510755_381629702657391_5934219896131944448_o.jpg?_nc_cat=100&_nc_ht=scontent.fbkk2-8.fna&oh=4d646deb48286776d47811c5e63ef83d&oe=5D640FA7">
                  <div class="fill-height bottom-gradient"></div>
                  </v-img>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-img class="mg"
                    src="https://scontent.fbkk2-7.fna.fbcdn.net/v/t1.0-9/54521997_381629685990726_443503476945715200_o.jpg?_nc_cat=101&_nc_ht=scontent.fbkk2-7.fna&oh=cf35835d11a035cd2aa604156488e07e&oe=5D56776F">
                  <div class="fill-height bottom-gradient"></div>
                  </v-img>
                </v-col>

              </v-row>
            </v-content>
          </v-col>
          <!-- Desktop -->
          <v-col cols="12" md="4" class="hidden-sm-and-down">
            <v-content class="white-bg shadow mt-24px">
              <v-col cols="12">
                <v-row justify="center">
                  <router-link
                    v-if="isSale"
                    :to="`/payment/cart/${sku}`"
                    class="button ltsp">
                    <v-btn color="error"><strong> BUY NOW </strong></v-btn>
                  </router-link>
                  <v-btn
                    v-else
                    class="button ltsp"
                    disabled
                  >
                    <strong> SOLD OUT </strong>
                  </v-btn>
                </v-row>
              </v-col>
              <v-container>
                <br/>
                <v-row dense no-gutters
                  v-for="salebox in salebox"
                  :key="salebox.id">
                  <v-col cols="2">
                    <v-row justify="center">
                      <v-img
                        :src="`${salebox.imgicon}`"
                        max-width="30px">
                      </v-img>
                    </v-row>
                  </v-col>
                  <v-col v-if="'link' in salebox" class="text-content">
                    <a :href="`${salebox.link}`">
                      {{ salebox.title }}</a>
                  </v-col>
                  <v-col v-else class="text-content">
                    {{ salebox.title }}
                  </v-col>
                  <!-- <v-col md="4" class="d-none d-sm-flex"></v-col>
                  <v-col cols="2" sm="2" md="4" class="text-xs-center "> -->
                    <!-- <v-icon>{{ salebox.icon }}</v-icon> -->
                    <!-- <v-img
                      :src="`${salebox.imgicon}`"
                      width="30px">
                    </v-img>
                  </v-col>
                  <v-col cols="10" sm="10" md="4">
                    <div class="text-xs-left pdr-16px">{{ salebox.title }}</div>
                  </v-col> -->
                </v-row>
                <br/>
                <v-row dense no-gutters >
                  <v-col cols="2">
                    <br/>
                    <v-row justify="center">
                      <v-img
                        :src="require('@/assets/image/icon-14.svg')"
                        max-width="30px">
                      </v-img>
                    </v-row>
                      <!-- <v-icon>fas fa-chalkboard-teacher</v-icon> -->
                  </v-col>
                  <v-col class="text-content">
                    <span v-if="preInstructor"><strong>{{preInstructor}}</strong><br/></span>
                    <span v-if="instructor">{{instructor}}<br/></span>
                    <span v-if="department">{{department}}<br/></span>
                    <span v-if="faculty">{{faculty}}<br/></span>
                    <span v-if="university">{{university}}<br/></span><br/>
                  </v-col>
                </v-row>
              </v-container>

            </v-content>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </body>
</template>

<script>
export default {
  name: 'workshopDetail',
  props: ['workshopId'],
  data() {
    return {
      card_text: '',
      sku: 'ws0010001',
      workshop: [
        { title: 'Python for Clustering Customer Segmentation รุ่น 1', date: '23/03/2562' },
      ],
      title: 'Python for Clustering Customer Segmentation รุ่น 1',
      date: '23/03/2562',
      price: '5,900 บาท',
      salebox: [
        {
          title: 'อบรมเชิงปฏิบัติการ (workshop)', icon: 'fas fa-laptop-code', imgicon: require('@/assets/image/icon-06.svg'), id: 1,
        },
        {
          title: 'วันเสาร์ที่ 23 มี.ค. 2562', icon: 'fas fa-calendar-alt', imgicon: require('@/assets/image/icon-09.svg'), id: 2,
        },
        {
          title: '08:00 - 18:00 น.', icon: 'far fa-clock', imgicon: require('@/assets/image/icon-10.svg'), id: 3,
        },
        {
          title: 'อาคารจามจุรี 10 จุฬาฯ', icon: 'fas fa-map-marker-alt', imgicon: require('@/assets/image/icon-11.svg'), id: 4,
        },
        {
          title: 'ระดับพื้นฐาน (Fundamental level)', icon: 'fas fa-tasks', imgicon: require('@/assets/image/icon-12.svg'), id: 5,
        },
        {
          title: 'Achieve+ Member จ่ายเพียง 2,990 บาท จากราคาเต็ม 5,900 บาท', icon: 'fas fa-star', imgicon: require('@/assets/image/icon-13.svg'), id: 6,
        },
      ],
      isSale: new Date('2019-03-22T09:00:00.000Z') > new Date(),
      preInstructor: 'สอนโดย',
      instructor: 'ผศ.ดร.สุกรี สินธุภิญโญ',
      department: 'ภาควิชาวิศวกรรมคอมพิวเตอร์',
      faculty: '',
      university: 'จุฬาลงกรณ์มหาวิทยาลัย',
    };
  },
};
</script>
<style scoped>
.ct-mw{
  max-width: 1100px;
  margin: auto;
}
.banner-bg{
  background-color: #001544;
  /* margin-left: -600px;
  margin-right: -600px; */
}
.banner-content{
  max-width: 1100px;
  margin: auto;
}
.content{
  height: 1500px;
  margin: auto;
  max-width: 1200px;
}
.heading{
  font-size: 1.3125rem;
  font-weight: 600;
  letter-spacing: 2px;
}
.pd-24px{
  margin-left: 31px;
  margin-right: 32px;
}
.pd-12px{
  padding-left: 12px;
}
.pdr-16px{
  padding-left: 24px;
  padding-right: 16px;
}
.white-bg{
  box-shadow: #001544;
  border: #001544;
}
.shadow{
  box-shadow: 2px 2px 10px #dfdfdf;
  border-color: #001544;
}
.mt-24px{
  margin-top: 24px;
}
.mlr-24px{
  margin-left: 24px;
  margin-right: 24px;
}
.v-icon{
  font-size: 16px;
}
.mgt-12px{
  margin-top: 12px;
}
.v-btn__content{
  font-weight: 700;
}
.ltsp{
  font-weight: 700;
  letter-spacing: 2px;
}
.mg{
  margin-bottom: 16px;
}
.text-content{
  font-size: 0.875rem;
}
@media screen and (max-width: 770px){
  .heading{
    font-size: 1.0rem;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 1.4rem;
  }
}
</style>
