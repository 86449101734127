<template>
  <div>Loging Out...</div>
</template>


<script>
import router from '@/router';

export default {
  created() {
    this.$store.dispatch('logout');
    this.$store.dispatch('beAnon');
    router.push('/');
  },
};
</script>
