<template>
  <v-container class="py-0">
    <!-- <v-row class="bg-white">
      <v-img :src="require('../../assets/image/member-success-platinum.jpg')"
        contain
        max-height="300px"
      ></v-img>
    </v-row> -->
    <v-content>
      <v-row justify="center">
        <v-col cols="12" sm="10" lg="6">
          <v-card>
            <v-container>
              <v-row justify="center">
                <v-card-title
                  class="navy-text font-weight-black"
                >ดำเนินการสำเร็จ!</v-card-title>
              </v-row>
              <v-row justify="center">
                <span class="navy-text text-center header-content font-weight-bold">
                  ขอบคุณที่สมัครเป็นส่วนหนึ่งของเรา
                </span>
              </v-row>
              <v-row justify="center" class="pb-2">
                <span class="navy-text header-content font-weight-bold">
                  ท่านสามารถตรวจสอบสถานะของท่านได้ที่
                </span>
              </v-row>
              <v-row justify="center" class="py-2">
                <v-btn
                    color="error"
                    to="/profile/member">
                    <span class="white-text">
                      หน้า Profile
                    </span>
                  </v-btn>
              </v-row>
              <v-row justify="center" class="py-2">
                <v-btn
                  color="error"
                  outlined
                  to="/"
                >
                  ไปหน้าหลัก
                </v-btn>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-content>

  </v-container>
</template>

<script>
export default {
  data() {
    return {
      fnameth: '',
      lnameth: '',
      memberLevel: '',
    };
  },
  // computed: {
  //   profile() {
  //     return this.$store.getters.profile;
  //   },
  // },
  async mounted() {
    const { uid } = this.$store.getters.user;
    await this.$store.dispatch('getProfile', { uid });
    const { profile } = this.$store.getters;
    this.fnameth = profile && profile.fth ? profile.fth : null;
    this.lnameth = profile && profile.lth ? profile.lth : null;
    this.memberLevel = profile && profile.memberLevel ? profile.memberLevel : null;
  },
};
</script>

<style scoped>
.bg-white{
  background-color: #FFFFFF;
}
</style>
